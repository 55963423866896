import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SeoComponent from "../components/seoComponent"
import posed from "react-pose"
import PageHeader from "../components/pageHeader/pageHeader"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Inner from "../components/inner"
import TeamListingsAll from "../components/team/team"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TeamPage = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  const { page } = useStaticQuery(TEAM_CONTENT)

  return (
    <Layout>
      <Header />
      <SeoComponent seo={page.seo} />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <PageHeader
          heading={page.acfPageHeader.heading}
          subHeading={page.acfPageHeader.subHeading}
          bg={page.acfPageHeader.headerImage.sourceUrl}
          compact={page.acfPageHeader.compact}
          bgPosition="right"
          mobileHeight="154px"
        />
        <Inner>
          <TeamListingsAll
            introTitle={page.acfPageIntro.introTitle}
            introContent={page.acfPageIntro.introParagraph}
          />
        </Inner>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default TeamPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const TEAM_CONTENT = graphql`
  query TeamDetails {
    page: wpPage(databaseId: { eq: 29 }) {
      seo {
        ...SEO
      }
      acfPageIntro {
        introParagraph
      }
      acfPageHeader {
        heading
        subHeading
        compact
        headerImage {
          sourceUrl
        }
      }
    }
  }
`
