import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TeamMember = ({ item }) => (
  <ItemWrapper to={`/team/${item.slug}`}>
    <Member>
      <ImageBox>
        <Img
          fluid={item.acfTeam.image.localFile.childImageSharp.fluid}
          alt={item.title}
        />
      </ImageBox>
      <ContentBox>
        <h3>{item.title}</h3>
        <p>{item.acfTeam.education}</p>
      </ContentBox>
      <Areas>
        <p>{item.acfTeam.preferredAreasOfPractice}</p>
      </Areas>
    </Member>
  </ItemWrapper>
)
export default TeamMember

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ItemWrapper = styled(Link)`
  display: block;
  transition: all 0.2s ease-in-out;
  color: #fff;
  text-align: left;
  &:hover {
    cursor: pointer;
    background-size: 110% auto;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

const ImageBox = styled.div`
  img {
    width: 100%;
    margin-bottom: 0px;
    display: block;
  }
`

const Member = styled.div`
  border-radius: 5px;
  overflow: hidden;
`

const Areas = styled.div`
  background-color: #d8d8d84d;
  padding: 20px;
  p {
    color: ${(props) => props.theme.green};
    margin-bottom: 0px;
    min-height: 69px;
  }
`

const ContentBox = styled.div`
  background-color: ${(props) => props.theme.green};
  padding: 20px;
  h3 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    color: #bd8b00;
    min-height: 46px;
    font-size: 16px;
    margin-bottom: 0px;
  }
`
